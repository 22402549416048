import React, { useState } from 'react';
import Authorization from 'hocs/Authorization';
import { UserRole } from 'models/user';
import isFxMarketClosed from 'utils/fxTrades/isFxMarketClosed';
import t from 'utils/translationHelper';

import { TradeDto, TradeStatus } from '@alpha/fx-dtos';
import MenuDropdownItem from '@alpha/ui-lib/ui/atoms/MenuDropdownItem/MenuDropdownItem';
import { ActionButton } from '@alpha/ui-lib/ui/button';
import { Box } from '@alpha/ui-lib/ui/external';
import APMenu from '@alpha/ui-lib/ui/molecules/Menu/Menu';
import { APTooltip } from '@alpha/ui-lib/ui/Tooltip/APTooltip';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowDown,
  faBolt,
  faDownload,
  faEye,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useStyles from '../Drawdowns/Drawdowns.styles';

interface IProps {
  handleOpenNewDrawdownDrawer: () => void;
  handleOpenNewFirstPartyDrawdownDrawer: () => void;
  handleOpenNewSpotDrawer?: () => void;
  handleContractDownload: () => void;
  handleDrawdownRequest?: () => void;
  handleCancelTrade?: () => void;
  handleOpenAccountDrawer?: () => void;
  showNewDrawdownButton: boolean;
  shouldDisableDrawdown: boolean;
  drawdownDisableReason?: string;
  showRequestDrawdownButton?: boolean;
  showBookSpotButton?: boolean;
  showContractNoteButton: boolean;
  showCancelTradeButton: boolean;
  hasDrawdownInputterRole: boolean;
  hasFirstPartyDrawdownInputterRole: boolean;
  hasLinkedPayments: boolean | undefined;
  eMoneyDisabled?: boolean | undefined;
  trade?: TradeDto;
}

const RightAlignedCta: React.FC<IProps> = ({
  handleOpenNewDrawdownDrawer,
  handleOpenNewFirstPartyDrawdownDrawer,
  handleOpenNewSpotDrawer,
  handleDrawdownRequest,
  handleContractDownload,
  handleCancelTrade,
  handleOpenAccountDrawer,
  showNewDrawdownButton = true,
  shouldDisableDrawdown,
  drawdownDisableReason,
  showRequestDrawdownButton = false,
  showBookSpotButton = false,
  showContractNoteButton,
  showCancelTradeButton,
  hasLinkedPayments,
  hasFirstPartyDrawdownInputterRole,
  hasDrawdownInputterRole,
  eMoneyDisabled,
  trade,
}: IProps) => {
  const [openDrawdownMenu, setOpenDrawdownMenu] = useState<null | HTMLElement>(
    null
  );
  const classes = useStyles();

  let drawdownMenuOptions = [];

  if(trade?.status && ![TradeStatus.AWAITING_SETTLEMENT].includes(trade?.status)) {
    if(trade?.defaultSSI && !eMoneyDisabled) {
      drawdownMenuOptions.push(
        {
          content: t('new_drawdown'),
          icon: faArrowDown,
          onClick: () => {
            handleOpenNewDrawdownDrawer();
          },
        },
        {
          content: t('quick_drawdown'),
          icon: faBolt,
          onClick: () => {
            handleOpenNewFirstPartyDrawdownDrawer();
          },
        }
      )
    }else {
      drawdownMenuOptions.push(
          {
            content: t('new_drawdown'),
            icon: faArrowDown,
            onClick: () => {
              handleOpenNewDrawdownDrawer();
            },
          },
          {
            content: t('new_first_party_drawdown'),
            icon: faArrowDown,
            onClick: () => {
              handleOpenNewFirstPartyDrawdownDrawer();
            },
          },
      );
    }
  }


  return (
    <Box display='flex' flexDirection='row'>
      {showContractNoteButton && (
        <ActionButton
          style={{
            background: '#F7F7F7',
            color: '#212529',
            marginRight: '10px',
          }}
          startIcon={<FontAwesomeIcon icon={faDownload as IconProp} />}
          testId='download-contract-note-btn'
          size='medium'
          onClick={handleContractDownload}
        >
          {t('download_contract_note')}
        </ActionButton>
      )}
      {eMoneyDisabled && (
        <ActionButton
          style={{
            background: '#F7F7F7',
            color: '#212529',
            marginRight: '10px',
          }}
          startIcon={<FontAwesomeIcon icon={faEye as IconProp} />}
          testId='view-settlement-details-btn'
          rounded={false}
          onClick={handleOpenAccountDrawer}
        >
          {t('view_settlement_details')}
        </ActionButton>
      )}
      {showBookSpotButton && (
        <Authorization requiredRoles={[[UserRole.SPOT]]}>
          <APTooltip
            placement='bottom-end'
            arrow
            title={
              isFxMarketClosed()
                ? t('fx_execution_is_not_available_at_this_time1')
                : ''
            }
          >
            <div>
              <ActionButton
                testId='book-spot-btn'
                rounded={false}
                disabled={isFxMarketClosed()}
                onClick={handleOpenNewSpotDrawer}
              >
                {t('book_spot_trade')}
              </ActionButton>
            </div>
          </APTooltip>
        </Authorization>
      )}
      {showCancelTradeButton && (
        <Authorization
          requiredRoles={[[UserRole.SPOT, UserRole.SPOT_INPUTTER]]}
        >
          <APTooltip
            placement='bottom-start'
            arrow
            title={
              hasLinkedPayments
                ? t('trades_that_have_linked_payments_cannot_be_cancelled')
                : ''
            }
          >
            <div>
              <ActionButton
                testId='cancel-spot-btn'
                style={{ marginLeft: '8px' }}
                rounded={false}
                disabled={hasLinkedPayments}
                onClick={handleCancelTrade}
              >
                {t('cancel_spot_trade')}
              </ActionButton>
            </div>
          </APTooltip>
        </Authorization>
      )}
      {showRequestDrawdownButton && (
        <Authorization requiredRoles={[[UserRole.DRAWDOWN_INPUTTER]]}>
          <ActionButton
            testId='drawdown-request-btn'
            rounded={false}
            onClick={handleDrawdownRequest}
          >
            {t('request_new_drawdown')}
          </ActionButton>
        </Authorization>
      )}
      {!showRequestDrawdownButton &&
        showNewDrawdownButton &&
        shouldDisableDrawdown && (
          <Authorization requiredRoles={[[UserRole.DRAWDOWN_INPUTTER]]}>
            <APTooltip
              title={
                drawdownDisableReason ||
                t('drawdowns_are_not_available_for_this_trade')
              }
              arrow
            >
              <div style={{ display: 'inline-block' }}>
                <ActionButton
                  testId='create-drawdown-btn'
                  rounded={false}
                  disabled={shouldDisableDrawdown}
                  onClick={handleOpenNewDrawdownDrawer}
                >
                  {t('new_drawdown')}
                </ActionButton>
              </div>
            </APTooltip>
          </Authorization>
        )}
      {!showRequestDrawdownButton &&
        showNewDrawdownButton &&
        !shouldDisableDrawdown &&
        hasFirstPartyDrawdownInputterRole &&
        hasDrawdownInputterRole && (
          <Authorization
            requiredRoles={[
              [
                UserRole.DRAWDOWN_INPUTTER,
                UserRole.FIRST_PARTY_DRAWDOWN_INPUTTER,
              ],
            ]}
          >
            <Box className={classes.drawdownButtonWrapper}>
              {drawdownMenuOptions.length > 0 &&
                <APMenu
                  open={Boolean(openDrawdownMenu)}
                  anchorEl={openDrawdownMenu}
                  setOpen={setOpenDrawdownMenu}
                  disableBackdropClick={false}
                  hideIcon
                  buttonTitle={t('new_drawdown')}
                >
                  {drawdownMenuOptions.map((item) => (
                    <MenuDropdownItem setOpen={setOpenDrawdownMenu}>
                      {item}
                    </MenuDropdownItem>
                  ))}
                </APMenu>
              }
            </Box>
          </Authorization>
        )}
      {!showRequestDrawdownButton &&
        showNewDrawdownButton &&
        !shouldDisableDrawdown &&
        hasDrawdownInputterRole &&
        !hasFirstPartyDrawdownInputterRole && (
          <Authorization requiredRoles={[[UserRole.DRAWDOWN_INPUTTER]]}>
            <ActionButton
              style={{ marginLeft: '10px' }}
              testId='drawdown-request-btn'
              rounded={false}
              onClick={handleOpenNewDrawdownDrawer}
            >
              {t('new_drawdown')}
            </ActionButton>
          </Authorization>
        )}
      {showNewDrawdownButton &&
        !shouldDisableDrawdown &&
        hasFirstPartyDrawdownInputterRole &&
        !hasDrawdownInputterRole && (
          <Authorization
            requiredRoles={[[UserRole.FIRST_PARTY_DRAWDOWN_INPUTTER]]}
          >
            <ActionButton
              style={{ marginLeft: '10px' }}
              testId='first-party-drawdown-request-btn'
              rounded={false}
              onClick={handleOpenNewFirstPartyDrawdownDrawer}
            >
              {t('new_first_party_drawdown')}
            </ActionButton>
          </Authorization>
        )}
    </Box>
  );
};

export default RightAlignedCta;
